/* You can add global styles to this file, and also import other style files */

@use "./styles/theme";
@use "colors";

html,
body {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: colors.$bg-color__white;
}

.mat-toolbar,
.mat-table,
.mat-paginator {
  background-color: colors.$bg-color__white;
}

.mat-dialog-container {
  padding: 0 !important;
}

ngx-skeleton-loader {
  display: block;
}

.loader {
  display: flex !important;
  margin: 0 !important;
}
