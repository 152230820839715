/** Background */
$bg-color__black: #3a3b3c;
$bg-color__gray: #929294;
$bg-color__light-gray: #f2f2f3;
$bg-color__white: #fbfbfb;
$bg-color__lookrec-blue: #2c70df;
$bg-color__lookrec-blue--light: #eaf0f9;
$bg-color__lookrec-blue--dark: #003cab;
$bg-color__green: #02863a;
$bg-color__green--light: #e7f2ec;
$bg-color__green--dark: #005307;
$bg-color__yellow: #f0bb3b;
$bg-color__yellow--light: #faf3e4;
$bg-color__yellow--dark: #a27e00;
$bg-color__red: #d43a45;
$bg-color__red--light: #f8ecec;
$bg-color__red--dark: #920404;
$bg-color__teal: #00897A;

/** Text */
$text-color__black: #3a3b3c;
$text-color__gray: #747476;
$text-color__white: #fbfbfb;
$text-color__lookrec-blue: #1d61d0;
$text-color__green: #00772b;
$text-color__red: #c32934;

/** Border */
$border-color--light: #e0e1e1;
$border-color--dark: #919292;

/** Overlay */
$overlay-color--light: rgba(58, 59, 60, 0.05);
$overlay-color--dark: rgba(58, 59, 60, 0.8);
